// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-master-card-page-master-card-page-js": () => import("./../../../src/components/Page/MasterCardPage/MasterCardPage.js" /* webpackChunkName: "component---src-components-page-master-card-page-master-card-page-js" */),
  "component---src-components-page-page-device-detector-js": () => import("./../../../src/components/Page/PageDeviceDetector.js" /* webpackChunkName: "component---src-components-page-page-device-detector-js" */),
  "component---src-components-page-page-js": () => import("./../../../src/components/Page/Page.js" /* webpackChunkName: "component---src-components-page-page-js" */),
  "component---src-components-page-page-static-js": () => import("./../../../src/components/Page/PageStatic.js" /* webpackChunkName: "component---src-components-page-page-static-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */)
}

