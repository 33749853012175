export default {
  inlineImagesOptions: {
    wordPressUrl: process.env.GATSBY_WP_URL,
    uploadsUrl: process.env.GATSBY_WP_URL + "app/uploads",
  },
  cookieSettings: {
    path: "/",
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    domain: process.env.GATSBY_COOKIE_DOMAIN,
    necessaryCookie: "allowNecessary",
    functionalCookie: "allowFunctional",
    statisticCookie: "allowStatistics",
    thirdpartyCookie: "allowThirdParty",
  },
  lottiRenderSettings: {
    preserveAspectRatio: "xMidYMid slice",

    filterSize: {
      width: "400%",
      height: "400%",
      x: "-200%",
      y: "-200%",
    },
  },

  postHogOptions: {
    api_host: "https://analytics.metro-fs.com",
    debug: process.env.NODE_ENV === "development",
    persistence: "memory",
    disable_cookie: true,
    autocapture: true,
    capture_pageview: true,
    disable_session_recording: true,
    record_session: "record-session",
    featureFlags: {
      record_session: "record-session",
    },
    session_recording: {
      maskAllInputs: true,
    },
  },
  onboardingHost: "get.metro-fs.com",
}
