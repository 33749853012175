const posthog = require("posthog-js")
const config = require("./config")

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (window.dataLayer !== undefined) {
    window.dataLayer.push({
      event: "onRouteChange",
      path: location.pathname,
    })
  }
}

exports.onClientEntry = () => {
  posthog.default.init(process.env.GATSBY_POSTHOG_TOKEN, {
    ...config.default.postHogOptions,
    loaded: function (posthog) {
      posthog.onFeatureFlags(() => {
        if (
          posthog.isFeatureEnabled(
            config.default.postHogOptions.featureFlags.record_session
          )
        ) {
          posthog.startSessionRecording()
        }
      })
    },
  })
}
